<template>
    <div class="body">
        <div class="rule-content">
            <div
                style="font-size: 36px; width: 100%; display: flex; align-items: center; justify-content: center; text-align: center;">
                游戏规则</div>
            <hr>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;这是回合制贪吃蛇。 与传统单人贪吃蛇不同的是，本贪吃蛇为双人对战，每回合玩家同时做出决策控制自己的蛇。
            <br>
            <hr>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;亲自上阵：
            <img src="../../../public/imgs/itself.jpg" style="width: 600px;">
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;玩家键盘输入<span style="font-size: 36px; background-color: lightblue;"> `W S A
                D` </span>控制己方蛇的移动方向。

            <br>
            <hr>
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我的Bot：
            <img src="../../../public/imgs/bot.jpg" style="width: 600px;">
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;下拉列表中除`亲自上阵`外都是由玩家创建的Bot。可点击导航栏右上方`用户名`-->`我的Bot`创建。
            <br>
            <hr>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;可以使用如下代码：
            <div class="hljs-container" codetype="java">
                <highlightjs language="java" :autodetect="false" :code="code"></highlightjs>
            </div>
        </div>
    </div>
    <div class="col-12" style="text-align: center; padding-top: 2vh;">
        <router-link style="text-decoration: none; color: white;" :to="{ name: 'home' }">
            <button type="button" class="btn btn-warning btn-lg">我已阅读，返回</button>
        </router-link>
    </div>
</template>

<script>
// import { ref } from 'vue'
export default {
    setup() {
        let code = `package com.kob.botrunningsystem.utils;

import java.io.File;
import java.io.FileNotFoundException;
import java.util.ArrayList;
import java.util.List;
import java.util.Scanner;


public class Bot implements java.util.function.Supplier<Integer>{
    static class Cell {
        public int x, y;
        public Cell(int x, int y) {
            this.x = x;
            this.y = y;
        }
    }

    private boolean check_tail_increasing(int step) { // 检测当前回合，蛇的长度是否增加
        if(step <= 10) return true;
        return step % 3 == 1;
    }

    public List<Cell> getCells(int sx, int sy, String steps) {
        steps = steps.substring(1, steps.length() - 1);
        List<Cell> res = new ArrayList<>();

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};
        int x = sx, y = sy;
        int step = 0;
        res.add(new Cell(x, y));
        for(int i = 0 ; i < steps.length() ; i ++) {
            int d = steps.charAt(i) - '0';
            x += dx[d];
            y += dy[d];
            res.add(new Cell(x, y));
            if(!check_tail_increasing( ++ step)) {
                res.remove(0);
            }
        }
        return res;
    }

    public Integer nextMove(String input) {
        String[] strs = input.split("#");
        int[][] g = new int[13][14];
        for (int i = 0, k = 0; i < 13; i++) {
            for (int j = 0; j < 14; j++, k ++) {
                if(strs[0].charAt(k) == '1') {
                    g[i][j] = 1;
                }
            }
        }

        int aSx = Integer.parseInt(strs[1]), aSy = Integer.parseInt(strs[2]);
        int bSx = Integer.parseInt(strs[4]), bSy = Integer.parseInt(strs[5]);

        List<Cell> aCells = getCells(aSx, aSy, strs[3]);
        List<Cell> bCells = getCells(bSx, bSy, strs[6]);

        for (Cell c : aCells) g[c.x][c.y] = 1;
        for (Cell c : bCells) g[c.x][c.y] = 1;

        int[] dx = {-1, 0, 1, 0}, dy = {0, 1, 0, -1};
        for (int i = 0; i < 4; i++) {
            int x = aCells.get(aCells.size() - 1).x + dx[i];
            int y = aCells.get(aCells.size() - 1).y + dy[i];
            if(x >= 0 && x < 13 && y >= 0 && y < 14 && g[x][y] == 0) {
                return i;
            }
        }

        return 0;
    }

    @Override
    public Integer get() {
        File file = new File("input.txt");
        try {
            Scanner sc = new Scanner(file);
            return nextMove(sc.next());
        } catch (FileNotFoundException e) {
            throw new RuntimeException(e);
        }
    }
}
`;

        // const timer = ref(60); //倒计时
        // const showTimer = ref(false);
        // const benginTimer = () => {
        //     showTimer.value = true;

        //     const times = setInterval(() => {
        //         if (timer.value === 0) {
        //             clearInterval(times);
        //             showTimer.value = false;
        //             timer.value = 60;
        //             return;
        //         } else {
        //             timer.value--;
        //         }
        //     }, 1000);
        // };
        return {
            code,
            //benginTimer,
        }
    }

}
</script>

<style scoped>
div.body {
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    color: white;
    font-weight: 600;
    font-style: italic;
    position: relative;
    max-height: 1000px;
    overflow-y: auto;
}

div.body::-webkit-scrollbar {
    display: none;
}

div.rule-content {
    position: relative;
    width: 90vw;
    /* background-color: rgba(0, 0, 0, 0.5); */
    height: 70vh;
    padding-left: 1vw;
    padding-right: 1vw;
    font-size: 24px;
}

/* 语法高亮 */
.hljs-container {
    margin-left: 8vw;
    position: relative;
    display: block;
    width: 80%;
    padding: 30px 5px 2px;
    overflow-x: hidden;
    line-height: 20px;
    text-align: left;
    background: #21252b;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 40%);
}

/** 3个点 */
.hljs-container::before {
    position: absolute;
    top: 10px;
    left: 15px;
    width: 12px;
    height: 12px;
    overflow: visible;
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
    white-space: nowrap;
    text-indent: 75px;
    background-color: #fc625d;
    border-radius: 16px;
    box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
    content: attr(codetype);
}

/** 滚动条 */
:deep(.hljs) {
    overflow-x: auto;
}

:deep(.hljs::-webkit-scrollbar) {
    width: 12px !important;
    height: 12px !important;
}

:deep(.hljs::-webkit-scrollbar-thumb) {
    height: 30px !important;
    background: #d1d8e6;
    background-clip: content-box;
    border: 2px solid transparent;
    border-radius: 19px;
    opacity: 0.8;
}

:deep(.hljs::-webkit-scrollbar-thumb:hover) {
    background: #a5b3cf;
    background-clip: content-box;
    border: 2px solid transparent;
}

:deep(.hljs::-webkit-scrollbar-track-piece) {
    width: 30px;
    height: 30px;
    background: #333;
}

::-webkit-scrollbar-button {
    display: none;
}
</style>