const AC_GAME_OBJECTS = [];

export class AcGameObject {
    constructor() {
        AC_GAME_OBJECTS.push(this);
        this.timedelta = 0; // 这一帧距离上一帧的时间间隔。要算出一帧物体移动的距离
        this.has_called_start = false; // 记录是否被执行过
    }

    start() { // 只执行一次,创建的时候执行一次

    }

    update() { // 除了第一帧以外，每一帧执行一次

    }

    on_destroy() { // 删除之前执行

    }

    destroy() { // 删除游戏对象
        this.on_destroy();

        for(let i in AC_GAME_OBJECTS) { // in 遍历下标
            const obj = AC_GAME_OBJECTS[i];
            if(obj === this) {
                AC_GAME_OBJECTS.splice(i);
                break;
            }
        }
    }
}

let last_timestamp; // 上一帧执行的时刻
const step = timestamp => {
    for(let obj of AC_GAME_OBJECTS) { // of 遍历的是值
        if (!obj.has_called_start) {
            obj.has_called_start = true;
            obj.start();
        } else {
            obj.timedelta = timestamp - last_timestamp;
            obj.update();
        }
    }


    last_timestamp = timestamp;
    requestAnimationFrame(step);
}

requestAnimationFrame(step)