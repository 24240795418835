<template>
    <PlayGround v-if="$store.state.pk.status === 'playing'" />
    <MatchGround v-if="$store.state.pk.status === 'matching'" />
    <ResultBoard v-if="$store.state.pk.loser != 'none'" />
    <div class="game-info" v-if="$store.state.pk.status === 'playing'">
        <div class="user-color-A"
            v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.a_id)">
            您出生在：左下角
            <div class="opponent-info">
                &nbsp;您的对手：&nbsp;&nbsp;
                <img :src="$store.state.pk.opponent_photo">
                {{ $store.state.pk.opponent_username }}
            </div>
        </div>

        <div class="user-color-B"
            v-if="$store.state.pk.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.pk.b_id)">
            您出生在：右上角
            <div class="opponent-info">
                &nbsp;您的对手：&nbsp;&nbsp;
                <img :src="$store.state.pk.opponent_photo">
                {{ $store.state.pk.opponent_username }}
            </div>
        </div>
    </div>
</template>

<script>
import PlayGround from '../../components/PlayGround.vue'
import MatchGround from '../../components/MatchGround.vue'
import ResultBoard from '../../components/ResultRoard.vue'
import { onMounted, onUnmounted } from 'vue'// 当组件被加载和被卸载时执行的函数
import { useStore } from 'vuex'


export default {
    components: {
        PlayGround,
        MatchGround,
        ResultBoard,
    },
    setup() {
        const store = useStore();
        const socketUrl = `wss://zhlvv.top/websocket/${store.state.user.token}/`;

        let socket = null;
        onMounted(() => {
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png"
            })

            //store.commit("updateLoser", "none");
            //store.commit("updateIsRecord", false);

            socket = new WebSocket(socketUrl);

            socket.onopen = () => {
                console.log("connected!");
                store.commit("updateSocket", socket);
            }

            socket.onmessage = msg => {
                const data = JSON.parse(msg.data);
                if (data.event === "start-matching") {  // 匹配成功
                    store.commit("updateOpponent", {
                        username: data.opponent_username,
                        photo: data.opponent_photo,
                    });
                    setTimeout(() => {
                        store.commit("updateStatus", "playing");
                    }, 200);
                    store.commit("updateGame", data.game);
                } else if (data.event === "move") {
                    console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;
                    snake0.set_direction(data.a_direction);
                    snake1.set_direction(data.b_direction);
                } else if (data.event === "result") {
                    console.log(data);
                    const game = store.state.pk.gameObject;
                    const [snake0, snake1] = game.snakes;

                    if (data.loser === "all" || data.loser === "A") {
                        snake0.status = "die";
                    }
                    if (data.loser === "all" || data.loser === "B") {
                        snake1.status = "die";
                    }
                    store.commit("updateLoser", data.loser);
                }
            }



            socket.onclose = () => {
                console.log("disconnectd!");
            }
        });

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus", "matching");
            store.commit("updateLoser", "none");
            store.commit("updateIsRecord", false);
        })
    }
}
</script>

<style scoped>
div.game-info {
    text-align: center;
    position: absolute;
    width: 36%;
    height: 120px;
    margin-left: 32%;
    margin-top: -80px;
    background-color: rgb(50, 50, 50, 0.6);
}

div.user-color-A {
    color: blue;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

div.user-color-B {
    color: red;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

div.opponent-info {
    z-index: 20;
    color: white;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

div.opponent-info>img {
    border-radius: 50%;
    width: 10vh;
}
</style>