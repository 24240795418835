import $ from 'jquery'

export default {
    state: {
        id: "",
        username: "",
        photo: "",
        token: "",
        is_login: false,
        pulling_info: true, // 是否在获取信息当中
    },
    mutations: { // 一般用来修改数据 调用时需要用commit函数
        updateUser(state, user) {
            state.id = user.id;
            state.username = user.username;
            state.photo = user.photo;
            state.is_login = user.is_login;
        },
        updateToken(state, token) {
            state.token = token;
        },
        logout(state) {
            state.id = "";
            state.username = "";
            state.photo = "";
            state.token = "";
            state.is_login = false;
        },
        updatePullingInfo(state, pulling_info) {
            state.pulling_info = pulling_info;
        }
    },
    actions: { // 一般修改state的写在这
        login(context, data) {
            $.ajax({
                url: "https://zhlvv.top/api/user/account/token/",
                type: "post",
                data: {
                  username: data.username,
                  password: data.password,
                },
                success(resp) {
                    if(resp.error_message === "success") {
                        localStorage.setItem("jwt_token", resp.token); // 登录持久化
                        context.commit("updateToken", resp.token);
                        data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                  console.log(resp);
                }
              })
        },
        getinfo(context, data) {
            $.ajax({
                url: "https://zhlvv.top/api/user/account/info/",
                type: "get",
                headers: {
                  Authorization: "Bearer " + context.state.token,
                },
                success(resp) {
                    if(resp.error_message === "success") {
                        context.commit("updateUser", {
                            ...resp, // 解构resp
                            is_login: true,
                          })
                          data.success(resp);
                    } else {
                        data.error(resp);
                    }
                },
                error(resp) {
                  data.error(resp);
                }
              });
        },
        logout(context) {
            localStorage.removeItem("jwt_token");
            context.commit("logout");
        }
    },
    modules: {
    }
}