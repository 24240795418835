<template>
    <div class="menu-field">
        <div class="menu">
            <div class="menu-item">
                <router-link style="text-decoration: none; color: white;" :to="{ name: 'pk_index' }">
                    开始游戏
                </router-link>
            </div>
            <div class="menu-item">
                <router-link style="text-decoration: none; color: white;" :to="{ name: 'record_index' }">
                    对局列表
                </router-link>
            </div>
            <div class="menu-item">
                <router-link style="text-decoration: none; color: white;" :to="{ name: 'ranklist_index' }">
                    天梯积分榜
                </router-link>
            </div>
            <div class="menu-item">
                <router-link style="text-decoration: none; color: white;" :to="{ name: 'user_bot_index' }">
                    我的Bot
                </router-link>
            </div>
            <div class="menu-item">
                <router-link style="text-decoration: none; color: white;" :to="{ name: 'rules_index' }">
                    游戏规则
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
div.menu-field {
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

div.menu {
    width: 25vw;
    height: 70vh;
    background-color: rgba(0, 0, 0, 0.2);
}

div.menu-item {
    height: 13vh;
    width: 100%;
    font-size: 36px;
    line-height: 13vh;
    color: white;
    text-align: center;
    font-style: italic;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
}

div.menu-item:hover {
    scale: 1.2;
    transition: 200ms;
}
</style>