export default {
    state: {
        is_record: false,
        a_steps: "",
        b_steps: "",
        record_loser: "",
        current_page: 1,
    },
    mutations: { // 一般用来修改数据 调用时需要用commit函数
        updateIsRecord(state, is_record) {
            state.is_record = is_record;
        },
        updateSteps(state, data) {
            state.a_steps = data.a_steps;
            state.b_steps = data.b_steps;
        },
        updateRecordLoser(state, loser) {
            state.record_loser = loser;
        },
        updateCurrentPage(state, current_page) {
            state.current_page = current_page;
        }
    },
    actions: { // 一般修改state的写在这
    },
    modules: {
    }
}