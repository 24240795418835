<template>
    <div ref="parent" class="gamemap">
        <canvas ref="canvas" tabindex="0">

        </canvas>
    </div>
</template>

<script>
import { GameMap } from '../assets/scripts/GameMap'
import { ref, onMounted } from 'vue' // vue 里面定义变量都要用ref, onMounted组件加载完需要执行哪些操作
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore();
        let parent = ref(null);
        let canvas = ref(null);

        onMounted(() => { // 当组件加载完就创建对象
            store.commit(
                "updateGameObject",
                new GameMap(canvas.value.getContext('2d'), parent.value, store),
            );
        });

        return {
            parent,
            canvas,
        }
    }
}
</script>

<style scoped>
div.gamemap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>