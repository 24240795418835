<template>
    <PlayGround />
    <div class="back">
        <router-link class="reback" :to="{ name: 'record_index' }">返回</router-link>
    </div>
</template>

<script>
import PlayGround from '../../components/PlayGround.vue'

export default {
    components: {
        PlayGround,
    },
    setup() {
    }
}
</script>

<style scoped>
div.back {
    margin-left: 75vw;
    margin-top: -110px;
}

.reback {
    font-size: 48px;
    color: white;
    text-decoration: none;
    font-style: italic;
    position: relative;
    margin-bottom: 100px;
}
</style>